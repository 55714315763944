import React, { useContext } from "react";
import styled from '@emotion/styled';
import IcomoonIcon from '../../../shared/IcomoonIcon';
import { color, formStyles, breakpoints } from '../../../../utils/style';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import PopupWindow, { invokePopup } from '../../../../components/shared/PopupWindow';
import Star1 from './StarIcons/Star1';
import Star2 from './StarIcons/Star2';
import Star3 from './StarIcons/Star3';
import Star4 from './StarIcons/Star4';
import Star5 from './StarIcons/Star5';
import { Logout } from "../../../Auth/Auth";
import { withdrawRequestUrl } from "./Config";
import StoreContext from "../../../shared/Context/StoreContext";
import { getAuthToken, getAuthHeader } from "../../../Auth/Utils";
import { closeIframePopupHandler } from '../../../shared/PopupIframe';

const Wrapper = styled('div')`
  ${formStyles}
  position: relative;
  padding: 0 0 20px;
  .loading {
    position:relative;
  }
  .loading::before {
    content: "";
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background: ${color.black};
    opacity:0.7;
    z-index: 1;
  }
  .two-fields {
    .fieldWrap {
      width: 48%;
      display: inline-block;
      vertical-align: top;
    }
  }
  .red-button {
    float: right;
  }
  
  .green-button {
    margin: 0;
  }
  
  .fieldWrap .error {
    top: 43px;
  }
  
  .rating {
    float: left;
  }
  .rating input {
    display: none;
  }
  .rating label {
    display: inline-block;
    cursor: pointer;
    color: ${color.midGrey};
    float: right;
    font-size:30px;
    height: 40px;
    width: 40px;
    margin: 0;
    position:relative;
    &::after {
      content: "★";
      width: 40px;
      height:30px;
      display:inline-block;
      text-align:center;
      position: absolute;
      top: 0;
      left: 0;
    }
    span {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      width: 0;
    }
  }
  
 
  .rating > input:checked ~ label,
  .rating:not(:checked) > label:hover,
  .rating:not(:checked) > label:hover ~ label { color: ${color.orange};  }
  
  .rating > input:checked + label:hover,
  .rating > input:checked ~ label:hover,
  .rating > label:hover ~ input:checked ~ label,
  .rating > input:checked ~ label:hover ~ label { color: ${color.yellow};  } 
  @media (max-width: ${breakpoints.lg}) {
    width:100%;
    float:none;
    margin: 20px 0;
    .rating label {
      font-size:36px;
      width: 48px;
      height:48px;
      &::after {
        width: 48px;
        height:48px;
      }
    }
  }
`;

const StarFieldset = styled('fieldset')`
  border: none;
  float: left;
  padding: 20px 0 10px;
  legend {
    color: ${color.textGrey};
  }
  label {
    width: 36px;
    height: 36px;
    display: inline-block;
    border: 1px solid ${color.midGrey};
    text-indent: -9999px;
    overflow: hidden;
    border-radius: 20px;
    margin-right: 5px;
    cursor: pointer;
    padding: 0 !important;
    float: left;
    &:hover {
      border-color: ${color.white};
    }
  }
  
  input[type="radio"]:checked+label {
    border-color: ${color.yellow}
  }
  
  input[type="radio"] {
    display: none;
  }
`;

const AllStarWrapper = styled('div')`
  position: relative;
  height: 36px;
  width: 205px;
`;

const StarInputs = styled('div')`
  position: relative;
  z-index: 1;
`;

const StarContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  height: 36px;
  width: 205px;
`;

const OneStar = styled('div')`
  float: left;
  width:36px;
  height:36;
  padding: 2px;
  margin-right: 5px;
`;

const MsgWrap = styled('div')`
  margin-bottom: 30px;
  span {
    display:none;
    background-color:${color.yellow};
    color: ${color.black};
    padding:5px 10px;
    text-align:center;
    &.visible {
      display: block;
    }
  }
  h2, a {
    color: orange;
  }
  &.successMsg {
    display: none;
    &.visible {
      display: block;
      & + .game-withdraw-form, & + .withdraw-popup-window-title {
        display: none;
      }
    }
  }
  .link-on-live-chat {
    background: none;
    border: none;
    color: ${color.orange};
    font-size: 16px;
    
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const WithdrawFormOpen = (event, popupId) => {
  invokePopup(event, popupId);
};

const showErrorMsg = (msg) => {
  const errorMsg = document.getElementById('withdrawErrorMsg');
  errorMsg.innerText = msg;
  errorMsg.classList.add('visible');
};

const showSuccessMsg = () => {
  const errorMsg = document.getElementById('withdrawErrorMsg');
  errorMsg.classList.remove('visible');
  const successMsg = document.getElementById('withdrawSuccessMsg');
  successMsg.classList.add('visible');
  const popupTitle = document.getElementById('withdraw-popup-window-title');
  popupTitle.style.display = 'none';
};

const showHideChat = (id, frameID) => {
  const chatBox = document.getElementById(id);
  let chatIframe = document.getElementById(frameID);

  chatBox.classList.toggle('open');
  closeIframePopupHandler('withdraw-popup-window')

  if (chatBox.classList.contains('open') && (!chatIframe.src || chatIframe.src === 'about:blank')) {
    chatIframe.src = process.env.FRESHCHAT_IFRAME_SRC_NO_BUTTON;
  }
};

// Radio input
const RadioButton = ({
                       field: { name, value, onChange, onBlur },
                       id, label, ...props
                     }) => {
  return (
    <React.Fragment>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={id}><span>{label}</span></label>
    </React.Fragment>
  );
};


// Radio group
const RadioButtonGroup = ({
                            value, error, touched, id,
                            label, children
                          }) => {
  return (
    <StarFieldset className="emoji">
      <legend>{label}</legend>
      <AllStarWrapper>
        <StarContainer>
          <OneStar>
            <Star1 text="smiley"/>
          </OneStar>
          <OneStar>
            <Star2 text="crazy"/>
          </OneStar>
          <OneStar>
            <Star3 text="cowboy"/>
          </OneStar>
          <OneStar>
            <Star4 text="devil"/>
          </OneStar>
          <OneStar>
            <Star5 text="love"/>
          </OneStar>
        </StarContainer>
        <StarInputs>
          {children}
        </StarInputs>
      </AllStarWrapper>

    </StarFieldset>
  );
};

const StarFieldsetStars = styled('fieldset')`
  border: none;
  padding: 0 0 20px;
  clear: left;
`;


// Radio group
const RadioButtonGroupStars = ({
                                 value, error, touched, id,
                                 label, children
                               }) => {
  return (
    <StarFieldsetStars className="rating">
      <legend>{label}</legend>
      {children}
    </StarFieldsetStars>
  );
};

const initialValues = {
  username: '',
  comment: '',
  rating: '',
  emoji: '',
};

let submitPopupId = false;
const FormBody = ({ popupId }) => {
  submitPopupId = popupId;
  const StoreContextValue = useContext(StoreContext);
  const setUsernameCallback = StoreContextValue.setUsernameCallback;
  const onSubmit = async (values, { resetForm }) => {
    if (!getAuthToken) {
      return false;
    }
    values.subdomain = process.env.SUBDOMAIN;
    let body = JSON.stringify(values);
    const response = await fetch(withdrawRequestUrl, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthHeader()
      },
    });
    if (response.ok) {
      let json = await response.json();
      if (json.error && json.message) {
        showErrorMsg(json.message);
        resetForm({});
        console.log(json.message);
        if (json.error_type === 'session_expired') {
          Logout(false, setUsernameCallback);
        }
      } else {
        showSuccessMsg();
        resetForm({});
      }

    } else {
      console.log("Withdraw Error HTTP: " + response.status);
      let json = await response.json();
      if (json.code === 'jwt_auth_invalid_token') {
        Logout(false, setUsernameCallback);
      }

    }
  };

  return (
    <Wrapper>
      <MsgWrap id="loginMsg">
        <span className="errorMsg" id="withdrawErrorMsg">Borang gagal dihantar. Sila cuba lagi</span>
      </MsgWrap>
      <MsgWrap className="successMsg a-center" id="withdrawSuccessMsg">
        <h2>Hubungi kami menerusi Live Chat</h2>
        <p>Komen anda telah diterima.</p>
        <p>Sila hubungi pusat panggilan untuk menerima wang.</p>
        <p><button className="link-on-live-chat" onClick={() => showHideChat('chat-wrapper', 'chat-frame')} aria-label="Call">Pautan</button></p>
      </MsgWrap>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          username: Yup.string().required('Maklumat ini diperlukan'),
          comment: Yup.string().min(25, 'Minimum 25 aksara')
            .required('Maklumat ini diperlukan'),
          rating: Yup.string().required('Maklumat ini diperlukan'),
        })}
        render={({ errors, status, touched, isSubmitting, isValid, values }) => (
          <Form id="withdrawForm" className="game-withdraw-form">
            <div className="labelField">
              <label htmlFor="username">Nama Pengguna</label>
              <div className="fieldWrap iconField">
                <IcomoonIcon icon="user" color={color.white} size={18}/>
                {errors.username && touched.username && <div className="error-new">{errors.username}</div>}
                <Field type="text" name="username" className="username" placeholder="Nama Pengguna (e.g. AOOG000)" value={values.username || ''}/>
              </div>
            </div>

            <div className="labelField">
              <label htmlFor="comment">Komen Anda</label>
              <div className="fieldWrap iconField">
                {errors.comment && errors.comment &&
                touched.comment && <div className="error-new">! {errors.comment}</div>}
                <Field component="textarea" id="comment" name="comment"
                       placeholder="Great game. Just went in and won $100 in just a copuple of spins. I recommend!"
                       value={values.comment || ''}/>
              </div>
            </div>

            <RadioButtonGroup
              id="emoji"
              label="Bagaimana anda rasa"
              value={values.emoji}
              error={errors.emoji}
              touched={touched.emoji}
            >
              <Field
                component={RadioButton}
                name="emoji"
                id="smiley"
                label="5"
              />
              <Field
                component={RadioButton}
                name="emoji"
                id="crazy"
                label="4"
              />
              <Field
                component={RadioButton}
                name="emoji"
                id="cowboy"
                label="3"
              />
              <Field
                component={RadioButton}
                name="emoji"
                id="devil"
                label="2"
              />
              <Field
                component={RadioButton}
                name="emoji"
                id="love"
                label="1"
              />
            </RadioButtonGroup>
            {errors.emoji && touched.emoji && <div className="error">! {errors.rating}</div>}

            <RadioButtonGroupStars
              id="rating"
              value={values.radioGroup}
              error={errors.radioGroup}
              touched={touched.radioGroup}
            >
              <Field
                component={RadioButton}
                name="rating"
                id="star5"
                label="5 stars"
              />
              <Field
                component={RadioButton}
                name="rating"
                id="star4"
                label="4 stars"
              />
              <Field
                component={RadioButton}
                name="rating"
                id="star3"
                label="3 stars"
              />
              <Field
                component={RadioButton}
                name="rating"
                id="star2"
                label="2 stars"
              />
              <Field
                component={RadioButton}
                name="rating"
                id="star1"
                label="1 star"
              />
            </RadioButtonGroupStars>


            {status && status.msg && <div>{status.msg}</div>}
            <div className="clear a-center">
              <button className="button transparent-button" type="submit" disabled={(isSubmitting || !isValid)}>
                Hantar
              </button>
            </div>
          </Form>
        )}
      />

    </Wrapper>
  );
};

const WithdrawForm = ({ popupId }) => {
  return (
    <PopupWindow title="Pengeluaran wang" popupId={popupId} extraClass="a-left">
      <FormBody popupId={popupId}/>
    </PopupWindow>
  );
};

export default WithdrawForm;
export { WithdrawFormOpen };